import React, { useState, useEffect } from "react";
import { IoHeart, IoGitCompare } from "react-icons/io5";
import { Link, useNavigate } from "react-router-dom";
import "../styles/ProductCard.css";
import { useStateContext } from "../context/ContextProvider";
import { HiOutlineCursorArrowRipple } from "react-icons/hi2";

const ProductCard = ({ products }) => {
  const { lang } = useStateContext();

  const {
    likedProducts,
    setLikedProducts,
    productComparisons,
    addProductToComparison,
    error,
  } = useStateContext();

  useEffect(() => {
    const storedLikedProducts = localStorage.getItem("likedProducts");
    if (storedLikedProducts) {
      setLikedProducts(JSON.parse(storedLikedProducts));
    }
  }, [setLikedProducts]);

  const handleLike = (productSlug) => {
    console.log("At product like", productSlug, likedProducts);

    setLikedProducts((prevLikedProducts) => {
      let updatedLikedProducts;

      if (prevLikedProducts.includes(productSlug)) {
        updatedLikedProducts = prevLikedProducts.filter(
          (slug) => slug !== productSlug
        );
      } else {
        updatedLikedProducts = [...prevLikedProducts, productSlug];
      }

      console.log("After product like", updatedLikedProducts);
      localStorage.setItem(
        "likedProducts",
        JSON.stringify(updatedLikedProducts)
      );

      return updatedLikedProducts;
    });
  };

  const handleCompareClick = (productId) => {
    if (error) {
    }
    addProductToComparison(productId);
  };

  if (!products) {
    return (
      <div className="text-center m-5">
        Sizin sorğunuza uyğun elan tapılmadı
      </div>
    );
  }
  return (
    <>
      <div className="col-12 col-xs-12 col-md-12 col-lg-12">
        <div className="row">
          {products?.map((product, index) => (
            <div
              className="col-12 col-sm-6 col-xs-6 col-md-6 col-lg-3 col-xl-3 p-2"
              key={index}
            >
              <div class="single-product rounded">
                <div class="product-image">
                  <Link
                    to={`/az/product/${product.slug}`}
                    className="w_100_f w-100"
                  >
                    <img src={product.coverImage} alt="product" />
                  </Link>

                  <div class="button d-flex align-items-center justify-content-center gap-2">
                    <Link
                      to={`/${lang}/product/${product.slug}`}
                      className="btn"
                    >
                      Məhsulu gör
                    </Link>
                  </div>
                  <a
                    className="hearth-button position-absolute top-0"
                    href={`/az/cart/${product.slug}`}
                    style={{
                      background: "transparent",
                      right: "120px",
                    }}
                  >
                    <HiOutlineCursorArrowRipple
                      style={{
                        color: "#aaa",
                      }}
                    />
                  </a>
                  <button
                    className="hearth-button position-absolute top-0 end-0"
                    style={{
                      background: likedProducts.includes(product.slug)
                        ? "red"
                        : "transparent",
                    }}
                  >
                    <IoHeart
                      onClick={(event) => {
                        event.preventDefault();
                        handleLike(product.slug);
                      }}
                      style={{
                        color: likedProducts.includes(product.slug)
                          ? "#fff"
                          : "#aaa",
                      }}
                    />
                  </button>
                  <button
                    className="compare-button"
                    style={{
                      background: productComparisons.includes(product.slug)
                        ? "#0B2875"
                        : "transparent",
                    }}
                  >
                    <IoGitCompare
                      onClick={(event) => {
                        event.preventDefault();
                        handleCompareClick(product.slug);
                      }}
                      style={{
                        color: productComparisons.includes(product.slug)
                          ? "#fff"
                          : "#aaa",
                      }}
                    />
                  </button>
                </div>

                <div class="product-info">
                  <Link
                    to={`/az/product/${product.slug}`}
                    className="link w-100"
                  >
                    <h4 class="title">
                      <Link to={`/${lang}/product/${product.slug}`}>
                        {product.productTitle.length <= 36
                          ? product.productTitle
                          : `${product.productTitle.slice(0, 36)}...`}
                      </Link>
                    </h4>
                    <div class="price">
                    <span className="credit-price">{product.creditPrice} AZN</span>
                      <span className="main-price">
                        {product.price} <small> AZN</small>{" "}
                      </span>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default ProductCard;
